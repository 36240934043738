import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Box, type BoxProps, Spinner, Text, Center } from "@chakra-ui/react";
import { TenantContext } from "../shared/contexts/tenantContext";
import type { Position, Workplace } from "../shared/types";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { publicAssetUrl } from "../shared/helpers/urls";

interface GoogleMapProps extends BoxProps {
  setSelectedWorkplace?: (workplace: Workplace) => void;
  selectedWorkplace?: Workplace | undefined;
  workplaces: Workplace[];
  zoom?: number;
  extraNode?: React.ReactNode;
}

export const GoogleMap: React.FC<GoogleMapProps> = ({
  setSelectedWorkplace,
  selectedWorkplace,
  workplaces,
  zoom = 14,
  extraNode,
  ...boxProps
}) => {
  const render = (status: Status) => {
    if (status === Status.FAILURE) {
      return <Text>Could not load Google Maps</Text>;
    }
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    );
  };

  return (
    <Box {...boxProps}>
      <Wrapper apiKey={process.env.googleMapsApiKey || ""} render={render}>
        <MapLoader
          setSelectedWorkplace={setSelectedWorkplace}
          selectedWorkplace={selectedWorkplace}
          workplaces={workplaces}
          center={{
            latitude: workplaces[0].latitude,
            longitude: workplaces[0].longitude,
          }}
          zoom={zoom}
          extraNode={extraNode}
          width={boxProps.width || "full"}
          height={boxProps.height || "100vh"}
        />
      </Wrapper>
    </Box>
  );
};

interface MapLoaderProps extends BoxProps {
  setSelectedWorkplace?: (workplace: Workplace) => void;
  selectedWorkplace?: Workplace | undefined;
  workplaces: Workplace[];
  center: Position;
  zoom: number;
  extraNode?: React.ReactNode;
}

const MapLoader: React.FC<MapLoaderProps> = ({
  setSelectedWorkplace,
  selectedWorkplace,
  workplaces,
  center,
  zoom,
  extraNode,
  ...boxProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const tenantConfiguration = React.useContext(TenantContext);

  useEffect(() => {
    async function initializeMap() {
      if (ref.current && !map) {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary(
          "marker"
        )) as {
          AdvancedMarkerElement: typeof google.maps.marker.AdvancedMarkerElement;
        };

        const newMap = new window.google.maps.Map(ref.current!, {
          mapId: tenantConfiguration.googleMapId,
          center: {
            lat: center.latitude as number,
            lng: center.longitude as number,
          },
          mapTypeControlOptions: {
            mapTypeIds: [],
          },
          zoom,
          streetViewControl: false,
          fullscreenControl: false, // we have our custom control instead
        });

        setMap(newMap);

        const markers = workplaces.map((workplace: Workplace) => {
          const div = document.createElement("div");
          const img = document.createElement("img");
          img.src = publicAssetUrl(
            `/${tenantConfiguration.name}/map_marker_icon.svg`
          );
          img.alt = "Marker Icon";
          div.appendChild(img);

          const marker = new AdvancedMarkerElement({
            map: newMap,
            position: {
              lat: workplace.latitude as number,
              lng: workplace.longitude as number,
            },
            title: workplace.name,
            content: div,
          });

          if (setSelectedWorkplace) {
            marker.addListener("click", () => {
              setSelectedWorkplace(workplace);
            });
          }

          return marker;
        });

        const renderer = {
          render: ({
            count,
            position,
          }: {
            count: number;
            position: google.maps.LatLng;
          }) => {
            const div = document.createElement("div");
            div.style.display = "flex";
            div.style.alignItems = "center";
            div.style.justifyContent = "center";
            div.style.position = "relative";

            // Create the image element for the icon
            const img = document.createElement("img");
            img.src = publicAssetUrl(
              `/${tenantConfiguration.name}/marker_cluster_icon.svg`
            );
            img.style.width = "40px";
            img.style.height = "40px";
            div.appendChild(img);

            // Create the label element for the count
            const label = document.createElement("div");
            label.style.position = "absolute";
            label.style.top = "50%";
            label.style.left = "50%";
            label.style.transform = "translate(-50%, -50%)";
            label.style.color = "white";
            label.style.fontSize = "10px";
            label.textContent = String(count);
            div.appendChild(label);

            return new google.maps.marker.AdvancedMarkerElement({
              map,
              position,
              content: div,
            });
          },
        };

        // add a marker clusterer to manage the markers:
        new MarkerClusterer({
          markers,
          map: newMap,
          renderer,
        });

        if (markers.length > 1) {
          const bounds = new google.maps.LatLngBounds();
          for (let i = 0; i < markers.length; i++) {
            bounds.extend((markers[i] as any).position);
          }
          newMap.fitBounds(bounds);
        }

        // if inside an iframe, push a custom full-screen control once the map is working:
        if (inIframe()) {
          window.google.maps.event.addListenerOnce(newMap, "idle", () => {
            const element = createFullScreenButton();
            newMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(
              element
            );
          });
        }
      }
    }
    initializeMap();
  }, [
    center.latitude,
    center.longitude,
    map,
    setSelectedWorkplace,
    tenantConfiguration.googleMapId,
    tenantConfiguration.name,
    workplaces,
    zoom,
  ]);

  useEffect(() => {
    if (selectedWorkplace?.latitude && selectedWorkplace.longitude) {
      const mapCenter = {
        latitude: selectedWorkplace.latitude,
        longitude: selectedWorkplace.longitude,
      };

      map?.panTo(
        new google.maps.LatLng(mapCenter.latitude, mapCenter.longitude)
      );
    }
  }, [map, selectedWorkplace?.latitude, selectedWorkplace?.longitude]);

  return (
    <Box position="relative">
      <Box ref={ref} id="map" {...boxProps} />
      {extraNode && <>{extraNode}</>}
    </Box>
  );
};

const createFullScreenButton = () => {
  const icon = document.createElement("img");
  icon.alt = "external link";
  icon.src =
    "data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M10%206v2H5v11h11v-5h2v6c0%20.5523-.4477%201-1%201H4c-.55228%200-1-.4477-1-1V7c0-.55228.44772-1%201-1h6Zm11-3v8h-2l-.0001-4.587-7.7928%207.7941-1.41421-1.4142L17.5849%205H13V3h8Z%22%20stroke%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E";

  icon.style.maxWidth = "100%";
  icon.style.height = "auto";
  icon.style.display = "block";
  icon.style.marginLeft = "auto";
  icon.style.marginRight = "auto";

  const anchorElement = document.createElement("a");
  anchorElement.href = `${window.location.origin}/doctors/map`;
  anchorElement.target = "_blank";
  anchorElement.title = "Click to see a larger map";
  anchorElement.appendChild(icon);

  anchorElement.style.width = "40px";
  anchorElement.style.height = "40px";
  anchorElement.style.backgroundColor = "#fff";
  anchorElement.style.border = "2px solid #fff";
  anchorElement.style.borderRadius = "3px";
  anchorElement.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
  anchorElement.style.cursor = "pointer";
  anchorElement.style.fontFamily = "Roboto,Arial,sans-serif";
  anchorElement.style.margin = "8px";
  anchorElement.style.padding = "6px";
  anchorElement.style.textAlign = "center";
  anchorElement.style.display = "block";

  return anchorElement;
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
